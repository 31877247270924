@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500;700&display=swap');

* {
    font-family: Ubuntu;
}

.brandStyle {
    color: #0a6fc3 !important;
    font-weight: bold;
    width: 200px;
    text-align: left;
}


.navStyle {
    background-color: #ffffff;

}




.headerImg {
    width: 100%;
}

.title {
    font-size: 3.6vw;
    font-weight: bolder;
    padding-top: 10%;
    margin-bottom: 40px;
    color: #173259;
    text-align: left;
    padding-left: 80px;
    width: 80%;
}

.subTitle {
    font-size: 1.7vw;
    font-weight: 500 !important;
    color: #173259;
    text-align: left;
    padding-left: 80px;
    width: 80%;
}



.cardInfo {
    background-color: #d4e8f9;
    border-radius: 5;
    padding: 75px 50px;
    margin: 0px 25px;
    width: 200px;
    box-shadow: 20px 20px 20px black;
    display: flex;
    justify-content: center;
}



.conHeader {

    width: 100%
}

.breakTop {
    z-index: 5000;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/bg-top.png);

}

.breakBottom {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../img/bg-bottom.png);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

}

.subHeader {
    font-size: 40px !important;
    font-weight: 800 !important;
    color: #0f60a3;
    margin-bottom: 50px;
}

.cardTxt:last-child {
    font-size: 25px !important;
    font-weight: 500;
    color: #0f60a3;
    margin-left: 40px !important;


}

.cardStyle {
    background-color: #ffffff !important;
    border-radius: 20px !important;
    border-color: #ffffff97 !important;
    width: 70%;
    text-align: center;
    margin: auto;
}

.factTable {
    margin: auto;
    width: 70%;
}


.productContainer {
    margin: 5%;
    text-align: center;
}




.productCard {
    margin-right: 20px;
    border-radius: 20px !important;
    margin-bottom: 20px !important;
    max-width: 35vw;
    border: 0 !important;
}

.productImg {
    border-radius: 20px 20px 0 0 !important;
    width: 100%;
    height: 300px;
}

.aboutImg {

    width: 100%
}

.showBtn {
    padding: 15px 60px !important;
    margin-top: 40px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #0f60a3 !important;
    background-color: white !important;
    border-radius: 20px !important;
    border-color: #afaeae !important;
}

.showBtn:hover {
    background-color: #0f60a3 !important;
    border-color: #0f60a3 !important;
    color: white !important;
}

.aboutContainer {
    margin: 5%;
    text-align: center;
}


.footer {
    background-color: #65a5e0c9 !important;
    padding: 20px 30px;
    text-align: center;

}

.contactH {
    font-size: 40px !important;
    color: #0f60a3;
    margin-bottom: 20px;
    margin-top: 15px;

}

.contactRow {
    margin: 20px 100px !important;
}

.contactP {
    font-size: 22px;
    color: white;
    text-align: left;
    text-decoration: none;

}

.container .container-fluid {
    padding-right: 0px !important;
}

@media only screen and (max-width: 650px) {



    .title {
        font-size: 40px;
        padding-left: 0px;
        margin: auto;
        width: 90%;
        padding-top: 10rem;
    }

    .subTitle {
        font-size: 15px;
        padding-left: 0px;
        margin: auto;
        width: 90%
    }

    .brandStyle {
        width: 100px;
    }

    .productContainer {
        margin: 10px !important;
        padding: 0px;

    }


    .productCard {
        margin-right: 0px;
        border-radius: 20px !important;
        margin-bottom: 20px !important;
        max-width: 75vw;
    }

    .showBtn {
        padding: 5px 10px !important;
        margin-top: 10px !important;
        font-size: 16px !important;

    }

    .subHeader {
        margin-top: 50px;
    }

    .productImg {
        height: 200px;
    }

    .cardStyle {
        width: 100%;
        margin-left: 5px;
    }

    .cardTxt:last-child {
        font-size: 20px !important;
        font-weight: 400;
        text-align: left;
        margin-left: 0px !important;
    }

    .footer {

        padding: 5px;
        text-align: left;
    }

    .contactRow {
        margin: 20px 10px !important;
    }


    .contactP {
        font-size: 16px;

    }

    .aboutImg {
        display: none;
    }

    .breakBottom {
        height: 100px;
    }
}